import { useRoute } from 'vue-router'
import { ref, onMounted, getCurrentInstance } from 'vue'
import { stickerAgentUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import DropdownAbsolute from '@/views/shared-components/DropdownAbsolute.vue'
import StickerAgentForm from '@/views/master/items/sticker-agent/StickerAgentForm.vue'
import moment from 'moment'

export default {
  name: 'StickerAgent',
  components: {
    DropdownAbsolute,
    StickerAgentForm
  },
  setup() {
    const app = getCurrentInstance()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const store = useStore()
    const data = ref([]) as any
    const sliderActive = ref(false)
    const dataEdit = ref(null) as any
    const op = ref()
    const calendarShow = ref()
    const inputSearch = ref('')
    const route = useRoute()
    const dateFilter = ref()
    const sendDateFilter: any = ref('')
    const filters = {
      search: '' as any,
      filter: 'Jenis eq \'Sticker\'',
      expand: '&$expand=Agen($select=Id,Nama)',
      createDate: ''
    }
    const redirectAdd = () => {
      dataEdit.value = null
      sliderActive.value = true
    }

    const redirectEdit = (item: any) => {
      dataEdit.value = item
      sliderActive.value = true
    }

    const showDropDown = (evt: any, item: any) => {
      if (item === 'filterCalendar') {
        calendarShow.value.toggle(evt)
      } else {
        dataEdit.value = item
        op.value.toggle(evt)
      }
    }

    const closeSidebar = () => {
      sliderActive.value = false
    }

    const getAllData = async (search = '') => {
      store.dispatch('showLoading')
      filters.search = await `${search}`
      const response = await stickerAgentUseCase.getLogLabel(filters)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      } else {
        data.value = response.result
      }
      store.dispatch('hideLoading')
    }

    const isActived = async (val: any) => {
      store.dispatch('showLoading')
      const dataForm = {
        Id: val.Id,
        IsActive: val.IsActive
      }
      const response = await stickerAgentUseCase.changeIsActive(dataForm)
      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: response.message, group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const changeIsActive = (val: any) => {
      $confirm.require({
        header: 'Status Aktif',
        message: $strInd.confirm.msgChange,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          isActived(val)
        },
        reject: () => {
          val.IsActive = !val.IsActive
        }
      })
    }
    const saveFilterDate = () => {
      sendDateFilter.value = moment(dateFilter.value).format('YYYY-MM-DD')
      filters.createDate = `CreateDate eq ${moment(sendDateFilter.value).format('YYYY-MM-DD')}`
      getAllData()
      calendarShow.value.hide()
    }
    const clearFilter = async () => {
      sendDateFilter.value = ''
      filters.createDate = ''
      getAllData()
      calendarShow.value.hide()
    }
    const deleteData = (item: any) => {
      $confirm.require({
        header: 'Stiker',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await stickerAgentUseCase.deleteData(item.Id)

          if (response.error) {
            $toast.add({
              severity: 'error', detail: 'Gagal menghapus stiker, silahkan coba kembali', group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successDeleteData, group: 'bc', life: 3000
            })
            getAllData()
          }
        }
      })
    }

    onMounted(() => {
      getAllData()
    })

    return {
      dataEdit,
      op,
      sliderActive,
      data,
      inputSearch,
      route,
      redirectAdd,
      redirectEdit,
      changeIsActive,
      closeSidebar,
      getAllData,
      showDropDown,
      deleteData,
      moment,
      calendarShow,
      saveFilterDate,
      clearFilter,
      sendDateFilter,
      dateFilter
    }
  }
}
