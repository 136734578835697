import {
  ref, onMounted, getCurrentInstance, reactive
} from 'vue'
import { stickerUseCase, getListAgent, stickerAgentUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'

export default {
  name: 'StickerAgentForm',
  props: {
    dataEdit: {
      default: () => null
    }
  },
  emits: ['closeSidebar', 'reloadData'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const store = useStore()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const dataEdit = ref(props.dataEdit)
    const title = ref('')
    const Id = ref(null)
    const route = useRoute()
    const listAgent = ref([])
    const listSticker = ref([])
    const submitted = ref(false)
    const dataForm = reactive({
      stickerId: null,
      jumlah: 0,
      agentId: null
    })
    const rules = {
      stickerId: {
        required: helpers.withMessage('Sticker harus diisi', required)
      },
      agentId: {
        required: helpers.withMessage('Agen harus diisi', required)
      },
      jumlah: {
        required: helpers.withMessage('Jumlah sticker harus diisi', required)
      },
    }
    const v$ = useVuelidate(rules, dataForm)

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    const dataAgent = async () => {
      const filters = {
        filter: 'IsActive eq true',
        select: '&$select=Id,Nama,Kode'
      }
      const response = await getListAgent(filters)
      listAgent.value = response.result
    }

    const dataSticker = async () => {
      const filters = {
        filter: 'IsActive eq true',
        select: '&$select=Id,Nama,Kode'
      }
      const response = await stickerUseCase.getAll(filters)
      listSticker.value = response.result
    }

    const proccessSubmit = async () => {
      store.dispatch('showLoading')
      const data = {
        AgenId: dataForm.agentId,
        StickerId: dataForm.stickerId,
        Jumlah: dataForm.jumlah,
      }
      const response = await stickerAgentUseCase.tambahStockStickerAgent(data)
      submitted.value = false
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
        })
        emit('reloadData')
        closeSidebar()
      }
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      proccessSubmit()
    }

    const deleteData = () => {
      if (!dataEdit.value) {
        $toast.add({
          severity: 'error', detail: 'Gagal menghapus sticker, silahkan coba kembali', group: 'bc', life: 3000
        })
        return
      }
      $confirm.require({
        header: 'Sticker',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await stickerUseCase.deleteData(dataEdit.value.Id)

          if (response.error) {
            $toast.add({
              severity: 'error', detail: 'Gagal menghapus sticker, silahkan coba kembali', group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successDeleteData, group: 'bc', life: 3000
            })
            emit('reloadData')
            closeSidebar()
          }
        }
      })
    }

    onMounted(() => {
      dataAgent()
      dataSticker()
    })
    return {
      title,
      dataEdit,
      route,
      submitted,
      dataForm,
      v$,
      submitData,
      deleteData,
      closeSidebar,
      listAgent,
      listSticker
    }
  }
}
